import React from 'react';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';


function CopyLink({ userDetails }) {
    const link = `https://dexfarming.com/signup?u=${userDetails?.username}`;
  
    const handleCopy = () => {
      navigator.clipboard.writeText(link);
      alert('Link copied to clipboard!');
    };
  
    return (
        <Typography
        style={{
          color: 'rgb(33, 43, 54)',
          fontSize: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        variant="subtitle2"
      >
        <GroupAddIcon style={{color: 'rgb(33, 43, 54)'}} />
        <span style={{ marginLeft: 15, marginRight: 8, textDecoration: 'underline' }}>{link}</span>
        <Tooltip title="Copy to clipboard">
          <IconButton size="small" onClick={handleCopy}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    );
  }

  export default CopyLink;
