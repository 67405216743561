  import axios from 'axios';


  const secureAxios = axios.create({
      baseURL: process.env.REACT_APP_SERVICE_APP_URL,
      withCredentials: true,
    });

  secureAxios.interceptors.request.use(config => {
      config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
      return config;
  });


  export default secureAxios