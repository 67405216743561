import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';

import web3ModalConfig from "../providerOption";
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';

import { Paper } from '@mui/material';
import mainlogo from '../assets/Binance-Icon-Logo.wine.png'
import logo from '../assets/logo.svg'
import { textAlign } from '@mui/system';

import binance from '../assets/binance.svg';
import coinbase from '../assets/coinbase.svg';
import crypto from '../assets/crypto.svg';
import karken from '../assets/karken.svg';


//import './welcome.css';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const theme = createTheme();

const styles = theme => ({
    multilineColor:{
        color:'red'
    }
});

const style = {"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '1px solid green'}}}

export default function Welcome() {
  const dispatch = useDispatch();
  let history = useHistory();

  const application = useSelector(state => ({
    ...state.application
  }));

  const [provider, setProvider] = React.useState();
  const [library, setLibrary] = React.useState();
  const [account, setAccount] = React.useState();
  const [network, setNetwork] = React.useState();
  const [chainId, setChainId] = React.useState();
  const [open, setOpen] = React.useState(false);

  const [isCssLoaded, setIsCssLoaded] = React.useState(false);

  React.useEffect(() => {
    const bodyElt = document.querySelector("body");
    bodyElt.style.background = "rgb(26,26,26)";
  
    return //This is important
   
  }, []);

  
  const handleAccountsChanged = (accounts) => {
    console.log({accounts})
  };

  const connectWallet = async () => {
    const provider = await web3ModalConfig.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();

    if (network?.chainId !== 56){
      alert("Wrong network selected")
      return;
    }
    setProvider(provider);
    setLibrary(library);
    if (accounts) setAccount(accounts[0]);
    setNetwork(network);
    setChainId(network.chainId);

    loginProcess(accounts[0], network.chainId);

  };

  const loginProcess = (address, chainId) => {
    setOpen(true);
    secureAxios
        .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/login`, {address: address}).then(response => {
          setOpen(false);
          if (response.data?.success){
            localStorage.setItem('accessToken', response.data?.token?.access);
            dispatch({
              type: LOGIN,
              payload: {
                profile: {
                  account: address,
                  chainId: chainId,          
                },
                isAuthenticated: true
              }
            });
            window.location.href = '/home'
            //history.push("/home");
          }
        }).catch(error => {
          setOpen(false);
          console.log(error)
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }

        })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    dispatch({
      type: LOGIN,
      payload: {
        profile: {},
        isAuthenticated: true,
        accessToken: {},
        groups: {}
      }
    });
    history.push("/home")

  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
  };

  useEffect(() => {
    const loadCss = async () => {
      // Dynamically import CSS files
      const cssFiles = await Promise.all([
        import('./welcome.css')
      ]);

      // Add the stylesheets to the document head
      const linkElements = cssFiles.map((cssFile) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = cssFile.default;
        document.head.appendChild(link);
        return link;
      });

      // Set state to indicate that CSS files are loaded
      setIsCssLoaded(true);

      return linkElements;
    };

    let cssLinks;
    loadCss().then((links) => {
      cssLinks = links;
    });

    return () => {
      // Cleanup: remove the CSS files when component unmounts
      if (cssLinks) {
        cssLinks.forEach((link) => document.head.removeChild(link));
      }
    };
  }, []);



  return (
    <>
    {!isCssLoaded && <h1>Loading the App...</h1>}
    {isCssLoaded && <div className="mainContainer">
      <nav id="navbar">
        <div className="navContainer container">
          <a href="/" className="logo alignY">
            <img src={logo} alt="Logo" />
            <span className="hideMobile">DexFarm</span>
          </a>
          <div className="alignY navLinks">
            <a href="#" target="_blank" rel="noopener noreferrer" className="navLink">
              Docs
            </a>
            <div className="navLinks alignY">
              {/* <a href="https://twitter.com/harvest_finance" target="_blank" rel="noopener noreferrer" className="navLink" style={{ width: 25, height: 20 }}>
                <img src={twitter} alt="Twitter" />
              </a>
              <a href="https://discord.gg/xHXe3tYjPY" target="_blank" rel="noopener noreferrer" className="navLink" style={{ width: 27, height: 20 }}>
                <img src={discord} alt="Discord" />
              </a>
              <a href="https://medium.com/harvest-finance" target="_blank" rel="noopener noreferrer" className="navLink" style={{ width: 20, height: 20 }}>
                <img src={medium} alt="Medium" />
              </a> */}
            </div>
          </div>
        </div>
      </nav>

      <main className="container">
        <div className="mainGrid">
          <div>
            <h1>Become a Crypto Farmer</h1>
            <h2>Auto-compound your crypto</h2>
            <div className="openApp alignY">
              <a className="openAppButton" onClick={connectWallet} href="#">Login</a>
              {/* <img src={coinbaseRating} alt="Coinbase Rating" /> */}
            </div>
            <div className="openApp alignY">
              <a className="openAppButton" href="/signup">Sing Up</a>
              {/* <img src={coinbaseRating} alt="Coinbase Rating" /> */}
            </div>
          </div>
          {/* <div className="animationContent">
            <div className="animationContainer">
              <div id="animation">
                
              </div>
            </div>
          </div> */}
        </div>
      </main>

      <footer id="footer">
        <div className="footerContainer container">
          <div className="footerLinks alignY">
            
          </div>
          <div className="footerLinks alignY">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={coinbase} alt="Least Authority" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={binance} alt="Heachi Audit" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={karken} alt="PeckShield" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={crypto} alt="CertiK" />
            </a>
          </div>
        </div>
      </footer>
    </div>}
    </>
      
  );
}