import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link, useLocation } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import secureAxios from '../../secureAxios';
import IBackdrop from '../backdrop.js';

import moment from 'moment/moment';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  

const getStatus = (type) => {
  const ENUM = {
    'hourly_mine': 'Reward Point (RP)',
    'hourly_mine_click': 'Hourly Reward',
    'team': 'Team Reward',
    'direct': 'Direct Referral',
    'hourly_mine_team': 'Team Reward',
    'daily_mine_click': 'Daily Reward',
    'daily_mine': 'Daily Farm (RP)'
  }

  return ENUM[type]
}
  
    const Demo = styled('div')(({ theme }) => ({
      backgroundColor: 'transparent',
    }));
  
  
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: 'rgb(33, 43, 54)'
      },
      subItemtitle: {
        color: '#eee',
        opacity: '0.5',
        fontSize: '8px'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: '#f0f5f9',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      },
      pan: {
        boxShadow:  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        background: 'rgb(255, 255, 255)'
      }
    }));

    const text = {
        color: "#eee",
        opacity: '0.7',
        fontSize: '11px',
        maxWidth: '150px'
    };

    const subItemtitle = {
      color: '#eee',
      fontSize: '13px'
    };
  
  const TeamRewardStateMent = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const classes = useStyles();
      const [dense, setDense] = React.useState(false);
      const [secondary, setSecondary] = React.useState(false);
      const [open, setOpen] = React.useState(false);
      const [statements, setStatements] = React.useState([]);
      const [page, setPage] = React.useState(1);
      const [noData, setNoData] = React.useState(false);
      const location = useLocation();
      const typeofParam = location.pathname.replace('/', '').split("/");

      useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

      const load_more = () => {
        setPage(page + 1);
        reawardDetails();
      }

      const reawardDetails = () => {
        setOpen(true);
        secureAxios
            .get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/team_reawards/statement?page=${page}`).then(response => {
              setOpen(false);
              if (response.data?.statements.length > 0){
                setStatements(prevStatements => [...prevStatements, ...response.data.statements]);
              }
              else{
                setNoData(true)
              }
            }).catch(error => {
              setOpen(false);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }    
            })
      }

      React.useEffect(() => {
        reawardDetails()
        return
      }, []);

      return (
      <>
      <IBackdrop isOpen={open} msg={"Signin progress"}/>
      {noData && <Typography className='no_data_text'>No data available</Typography>}

      {statements.map((item, index) => {
        return (
            <Paper 
            className={classes.pan}
            style={{background: 'rgb(24, 26, 32)'}}
             key={index}
            
                sx={{
                p: 0,
                margin: 'auto',
                maxWidth: 500,
                marginTop: index !== 0 ? '30px': '0',
                marginBottom: index == statements.length - 1 ? '70px' : 0,
                flexGrow: 1
                }}
            >
                <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                        <Demo>
                            <List dense={dense}>
                                <ListItem
                                    secondaryAction={
                                        <Button style={{
                                            borderRadius: 35,
                                            backgroundColor: "transparent",
                                            fontSize: "14px",
                                            color: 'rgb(33, 43, 54)',
                                            border: '0.5px solid rgb(33, 43, 54)',
                                            marginBottom: '10px'
                                        }} variant="contained">{item.amount.toFixed(6)} {item?.coin_code}</Button>
                                    }
                                >
                                    <ListItemText
                                    className={classes.title}
                                    primary={`${getStatus(item.reason)}`}
                                    secondary={
                                        <>
                                            <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12}} variant="subtitle2">{`${moment(item.date_created).format('DD-MM-YYYY, hh:mm A')}`}</Typography>
                                        </>
                                    }
                                    secondaryTypographyProps={{style: text}}
                                    />
                                </ListItem>
                            </List>
                        </Demo>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Paper>
            )
        })}

      {statements.length > 9 && !noData &&  <Button style={{
          borderRadius: 35,
          backgroundColor: "rgb(252, 213, 53) ",
          fontSize: "14px",
          color: 'rgb(33, 43, 54)',
          border: '0.5px solid rgb(33, 43, 54)',
          marginBottom: '70px',          
          marginTop: '-50px'
      }} variant="contained" onClick={load_more}>Load More</Button>}

      </>
      )
  }
  
  export default TeamRewardStateMent

