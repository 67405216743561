import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch} from 'react-redux';
import { SET_DRAWER } from '../redux/actions/types';

const useStyles = makeStyles({
  drawerPaper: {
    marginTop: "55px"
  }
});

export default function Sidebar() {
  const classes = useStyles();
  const application = useSelector(state => ({
    ...state.application
  }));
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    dispatch({
      type: SET_DRAWER,
      payload: { isDrawerOpen: false}
    });
  }
  
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"     
    >
      <List>
        {['Dashboard', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
        <SwipeableDrawer
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={toggleDrawer}
          open={false}
        >
          {list()}
        </SwipeableDrawer>
    </div>
  );
}