import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { SET_SCREEN_SIZE } from '../redux/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import web3ModalConfig from "../providerOption";
import { clear } from '@testing-library/user-event/dist/clear';
import Web3 from "web3";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  shiftContent: {
    paddingLeft: 190
  },
  content: {
    height: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    marginTop: '50px',
  }
}));

const Main = props => {
  const { children } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const application  = useSelector(state => ({
    ...state.application
  }));
  const size = 700;
  let history = useHistory()




  // React.useEffect(() => {
  //   if (application.isAuthenticated === false || application.isAuthenticated === null){
  //     history.push("/")
  //   }
  // }, []); 


  // const disconnect = async () => {
  //   console.log("disconnected")
  //   //await web3ModalConfig.clearCachedProvider();
  //   window.localStorage.clear();

  // };

  // const Logout = () => {
  //   dispatch({
  //     type: LOGIN,
  //     payload: {
  //       profile: {},
  //       isAuthenticated: false,
  //       accessToken: {},
  //       groups: {}
  //     }
  //   });
  //   history.push("/")
  // }


  // const connectWallet = async () => { 
  //   const provider = await web3ModalConfig.connect();    
  //   const handleAccountsChanged = (accounts) => {      
  //     if(accounts){
  //       if (accounts[0] !== localStorage.getItem('address')){
  //         localStorage.setItem('address', accounts[0]);
  //         dispatch({
  //           type: LOGIN,
  //           payload: {
  //             profile: {
  //               account: accounts[0],
  //               chainId: application.profile.chainId
  //             }
  //           }
  //         });
  //       }
  //     } 
  //   };

  //   const handleChainChanged = (chainId) => {
  //     if (chainId !== "0x38"){
  //       alert("Not a valid network");
  //       //Logout();
  //     }
  //   };

  //   const handleDisconnect = (dis) => {
  //     disconnect();
  //   };

  //   const handleError = (error) => {
  //     console.log(error)
  //   };

  //   provider.on("accountsChanged", handleAccountsChanged);
  //   provider.on("chainChanged", handleChainChanged);
  //   provider.on("disconnect", (code, reason) => {console.log(code, reason);});
  //   provider.on("error", handleError);

  //   return () => {
  //     // Return function of a non-async useEffect will clean up on component leaving screen, or from re-reneder to due dependency change
  //     provider.off('accountsChanged', handleAccountsChanged);
  //     provider.off('disconnect', (code, reason) => {console.log(code, reason);});
  //   }

  // };

  // React.useEffect(() => {

  //   const bodyElt = document.querySelector("body");
    
  //   const checkConnection = async () => {

  //     // Check if browser is running Metamask
  //     let web3;
  //     if (window.ethereum) {
  //         web3 = new Web3(window.ethereum);
  //     } else if (window.web3) {
  //         web3 = new Web3(window.web3.currentProvider);
  //     };

  //     // Check if User is already connected by retrieving the accounts
  //     web3.eth.getAccounts()
  //         .then(async (addr) => {
  //           console.log(addr)
  //           if (addr.length > 0){
  //             connectWallet();
  //           }
  //           else{
  //             Logout()
  //           }
  //             // Set User account into state
  //         });
  // };
  // checkConnection()

  // }, []);


  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <Header/>
      <Sidebar/>
      <main className={classes.content}>
        {children}
      </main>
      <Footer/>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;