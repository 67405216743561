import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useDispatch, useSelector} from 'react-redux';
import { SET_DRAWER } from '../redux/actions/types';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useHistory } from "react-router-dom";
import mainlogo from '../assets/logo.png'
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';


export default function Header() {

  const application = useSelector(state => ({
    ...state.application
  }));
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  let history = useHistory()


 


  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = () => {
    dispatch({
      type: LOGIN,
      payload: {
        profile: {},
        isAuthenticated: false,
        accessToken: {},
        groups: {}
      }
    });
    history.push("/")
  }

  const toggleDrawer = () => {
    dispatch({
      type: SET_DRAWER,
      payload: { isDrawerOpen: !application.isDrawerOpen}
    });
  }
 
    

  return (
    <Box sx={{ flexGrow: 1 }} style={{paddingBottom: '30px',  overflow: 'hidden'}}>
      <AppBar position="fixed" style={{background: '#181a20', overflow: 'hidden'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
           <Avatar style={{background: 'transparent', borderRadius: '0px'}}>
              <img style={{width: 25}} src={mainlogo} alt="fireSpot"/>
            </Avatar>
          </IconButton>
            <Grid container sx={{ flexGrow: 1 }}   justifyContent="center" direction="row" alignItems="center">
            <Grid item>
              {/* <Avatar style={{background: 'transparent'}}>
                  <img style={{width: 25}} src={mainlogo} alt="fireSpot"/>
              </Avatar> */}
            </Grid>
            <Grid item>
                <Typography variant="h6" component="div"><b>DEX</b> Farming</Typography>
            </Grid>
          </Grid>


          {/* 
           */}
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={Logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
